@import "./vars";

.what {
  width: 40px;
  height: 40px;
  background: $primary-blue-color;
  border-radius: 100%;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
