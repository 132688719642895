@import "./vars";

input {
  height: 40px;
  border: 1px solid $primary-border-color;
  background: #FFF;
  padding: 10px 12px;
  border-radius: 8px;
  color: $primary-text-color;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  &:hover {
    border: 1px solid $hover-border-color;
  }
  &:focus {
    border: 1px solid $primary-blue-color;
    box-shadow: none !important;
  }
  &::placeholder {
    color: $gray-text-color;
  }
  &.error {
    border: 1px solid $error-color;
  }
  &.ng-dirty.ng-invalid {
    border: 1px solid $error-color;
  }
  &[disabled] {
    background: $gray-background-input-color;
    &:hover {
      border: 1px solid $primary-border-color;
    }
  }
}
p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext{
  border: 1px solid $error-color;
}
label {
  color: $label-color;
  font-size: 14px;
  font-weight: 400;
}
