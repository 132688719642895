@import "./vars";

.head-block {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  .menu {
    display: flex;
    align-items: center;
    width: 100%;
    .logo {
      height: 40px;
      cursor: pointer;
    }
    .head-bred {
      padding: 27px 27px;
      position: relative;
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
      &_select {
        display: none;
        position: absolute;
        z-index: 9;
        background-color: $white-button-primary-color;
        border: 1px solid $primary-border-color;
        border-radius: 10px;
        padding: 16px;
        gap: 4px;
        width: 312px;
        left: -11px;
        top: 70px;
        &__item {
          width: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          a {
            display: block;
            text-decoration: none;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            color: $primary-text-color;
          }
          .zoom-block {
            text-align: center;
            padding: 0 0 6px;
            width: 100%;
            box-sizing: border-box;
            img {
              width: 50px;
              height: 50px;
            }
            p {
              font-size: 13px;
              margin: 0;
            }
          }
          &:hover {
            .zoom-block {
              background-color: #F5F5F5;
              border-radius: 8px;
              top: 0;
              width: 100%;
              z-index: 2;
            }
          }
        }
        &:hover {
          display: block;
        }
      }
      &:hover {
        cursor: pointer;
        .head-bred_select {
          display: flex;
          flex-wrap: wrap;
        }
        .head-bred_icon {
          path {
            fill: $primary-blue-color
          }
        }
      }
    }
    ul {
      display: flex;
      li {
        list-style: none;
        padding: 24px 16px;
        position: relative;
        text-align: center;
        &:hover {
          cursor: pointer;
          .link-bb {
            background: $primary-blue-color;
          }
        }
        &.active {
          .link-bb {
            background: $primary-blue-color;
            font-size: 20px;
          }
        }
        .link-bb {
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 2px;
          background: transparent;
          border-radius: 4px 4px 0 0;
        }
      }
    }
  }
  .head-right-block {
    display: flex;
    align-items: center;
    gap: 0 24px;
    .head-right-block_item {
      position: relative;
      transition: all 0.3s;
      &.heart {
        height: 16px;
      }
      &:hover {
        cursor: pointer;
        svg path {
          transition: stroke .2s ease;
          stroke: $primary-blue-color;
        }
        .clue {
          opacity: 1;
          visibility: visible;
        }
      }
      &.lang-switch {
        color: $primary-text-color;
        text-decoration: none;
        &:hover {
          opacity: .8;
          color: $primary-blue-color;
        }
      }
    }
  }
}
