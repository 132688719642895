@import "./vars";

[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  pointer-events: none;
  bottom:-63px;
  transform: translate(-50%);
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.tooltip:before,
[data-tooltip]:before {
  z-index: 10;
  content: url('/assets/images/header/Leftbeak.svg');
  top: 26px;
  left: 50%;
}

.tooltip:after,
[data-tooltip]:after {
  z-index: 9;
  padding: 8px 10px;
  width: max-content;
  max-width: 300px;
  background-color: #fff;
  color: $primary-text-color;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 140%;
  border: 1px solid #CED4DA;
  border-radius: 4px;
}
