@font-face
  font-family: Golos
  src: url('../assets/fonts/GolosText-Regular.ttf') format('truetype')
  font-style: normal
  font-weight: 400
  font-display: swap

@font-face
  font-family: Golos
  src: url('../assets/fonts/GolosText-Medium.ttf') format('truetype')
  font-style: normal
  font-weight: 500
  font-display: swap

@font-face
  font-family: Golos
  src: url('../assets/fonts/GolosText-SemiBold.ttf') format('truetype')
  font-style: normal
  font-weight: 600
  font-display: swap

@font-face
  font-family: Golos
  src: url('../assets/fonts/GolosText-Bold.ttf') format('truetype')
  font-style: normal
  font-weight: 700
  font-display: swap
