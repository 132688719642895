$primary-blue-color: #0D87EF;
$secondary-blue-color: #0076DA;
$third-blue-color: #E7F3FD;
$primary-text-color: #363636;
$secondary-text-color: #465564;
$gray-text-color: #6C757D;
$gray-button-text-color: #ADB5BD;
$primary-bg-color: rgba(0, 0, 0, 0.65);
$primary-border-color: #CED4DA;
$secondary-border-color: #F0F3F4;
$hover-border-color: #83C3FB;
$hover-company-block: #E7F3FD;
$gray-background-input-color: #DEE2E6;
$white-button-primary-color: #fff;
$gray-button-secondary-color: #F9F9F9;
$gray-text-color-new: #8D959D;
$gray-background-color: #F6F7F8;
$color-universal-btn: #071222;
$color-universal-btn-hover: #F5F5F5;
$color-contacts: #454545;
$color-massage: #2F2F2F;
$color-massage-error: #B30320;
$color-table: #E0E7ED;
$label-color: #495057;
$slash-color: #BBC4CB;
$error-color: #B20320;
$error-background-color: #FEEAED;
$error-second-color: #D51A52;
$error-border-color: #D11635;
$success-color: #00874C;
$success-second-color: #2DB77B;
$success-border-color: #05A660;
$success-background-color: #E3F5E8;
$warning-color: #FF8800;
$warning-background-color: #FEFFE5;
$yandex-banner-color: #323555;
$yandex-banner-color-secondary: #DBDEFF;
