@import "style-vars/fonts";
@import "style-vars/vars";
@import "style-vars/header";
@import "style-vars/tooltip";
@import "style-vars/buttons";
@import "style-vars/headlines";
@import "style-vars/what";
@import "style-vars/media";
@import "style-vars/inputs";

@import "../node_modules/swiper/swiper.min.css";
@import "../node_modules/swiper/swiper-bundle.min.css";

@import "primeicons/primeicons.css";
@import "../node_modules/primeflex/primeflex.css";

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  font-family: "Golos", sans-serif !important;
  color: $primary-text-color;
}
::-webkit-scrollbar{
  width: 4px;
}
::-webkit-scrollbar-track{
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb{
  background: #CED4DA;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover{
  background: #9ca4a9
}
.i-container {
  max-width: 1440px;
  min-width: 1024px;
  margin: 0 auto;
  position: relative;
  .i-row {
    padding: 0;
    height: 100%;
  }
}

.home-content {
  padding-bottom: 30px;
}

.grid-block {
  width: 100%;
  height: 100vh;
  .inside {
    height: 100%;
    .main {
      background: #F5F7F9;
    }
  }
}

.header {
  grid-area: header;
  background: $white-button-primary-color;
  position: relative;
  z-index: 20;
}

.main {
  grid-area: content;
}

jdiv.__jivoMobileButton {
  display: none
}

.indicator-dots {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50px !important;
}

.p-inputtext:enabled:focus {
  box-shadow: none;
}

.p-rating  .p-rating-icon{
  margin: 0 2px 0 0;
  cursor: default !important;
}

.accordion-wrap{
  .p-accordion .p-accordion-header{
    display: none;
  }
}

.p-rating {
  gap: 0;
}

.p-rating .p-rating-icon:focus{
  box-shadow: none;
}

.p-paginator-page{
  font-family: Golos;
  font-weight: 400;
  box-shadow: none !important;
  border-radius: 6px !important;
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  border: 1px solid $gray-button-text-color !important;
}

.p-paginator-page:hover{
  color: $primary-blue-color !important;
  border: 1px solid $primary-blue-color !important;
  background: transparent !important;
}

.p-paginator-page.p-highlight{
  color: $white-button-primary-color !important;
  background: $primary-blue-color !important;
  border: 1px solid $primary-blue-color !important;
}

.p-link:disabled{
  filter: blur(70px);
}

.p-paginator-page .p-link{
  background: transparent !important;
  box-shadow: none !important;
}

.fav-paginator{
  .p-paginator{
    background: transparent;
  }
}

.p-tooltip-text {
  display: flex;
  font-family: Golos;
  font-weight: 600;
  color: $primary-text-color !important;
  background: $white-button-primary-color !important;
  border: 1px solid $primary-border-color;
  box-shadow: none !important;
  height: 40px;
  font-size: 14px;
  border-radius: 8px !important;
  padding: 0 12px !important;
  align-items: center;
  margin-top: 9px;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: transparent;
  background-image: url('/assets/images/header/Leftbeak.svg');
  background-repeat: no-repeat;
  width: 100%;
  height: 24px;
  background-position: center;
  position: absolute;
  top: -0.5px;
  left: 5px;
  z-index: 20;
}

.carousel-img-wrap {
  font-family: "Golos", sans-serif;
  font-weight: 600;
  .first-slider-header {
    position: absolute;
    font-size: 18px;
    text-transform: uppercase;
    top: 50px;
    letter-spacing: 0.2px;
    left: 33px;
    span {
      color: #FFC90D;
    }
  }
  .first-slider-description {
    position: absolute;
    top: 81px;
    left: 33px;
    width: 248px;
    font-size: 15px;
    font-weight: 400;
    span {
      color: #FFC90D;
      font-weight: 600;
      font-size: 15px;
      display: inline;
    }
  }
  .first-slider-description-kz {
    position: absolute;
    top: 97px;
    left: 33px;
    width: 248px;
    font-size: 15px;
    font-weight: 400;
    h1 {
      color: #FFC90D;
      font-weight: 600;
      font-size: 15px;
      display: inline;
    }
  }
  .second-slider-header {
    position: absolute;
    font-size: 18px;
    top: 50px;
    letter-spacing: 0.2px;
    left: 24px;
    color: #0069C3;
  }
  .second-slider-description {
    position: absolute;
    top: 101px;
    left: 24px;
    width: 248px;
    font-size: 15px;
    font-weight: 400;
    color: #0069C3;
    span {
      font-weight: 600;
    }
  }
  .third-slider-header{
    position: absolute;
    font-size: 16px;
    top: 25px;
    letter-spacing: 0.2px;
    left: 24px;
    color: #FFF;
    width: 55%;
    text-transform: uppercase;
  }
  .third-slider-description {
    position: absolute;
    top: 79px;
    left: 24px;
    width: 43%;
    font-size: 12px;
    font-weight: 400;
    color: #FFF;
    span {
      font-weight: 600;
      color: $error-second-color;
    }
  }
  .banner-btn {
    position: absolute;
    bottom: 25px;
    left: 24px;
    background: #fff;
    padding: 6px 12px;
    border-radius: 8px;
    cursor: pointer;
    color: #363636;
  }
}

.good-card {
  .p-tabview-nav {
    justify-content: center;
  }
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: $primary-text-color;
  font-family: Golos;
  font-weight: 500;
  margin-top: 0;
  position: relative;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link{
  color: $primary-text-color;
  font-family: Golos;
  font-weight: 400;
  border-width: 0 0 2.5px 0;
}

.moderator-block {
  .p-tabview .p-tabview-nav li .p-tabview-nav-link{
    border-width: 0 0 3px 0;
  }
}

.acc-tabs {
  .p-tabview .p-tabview-nav {
    background: #F9FAFA;
     li .p-tabview-nav-link {
      background: #F9FAFA;
    }
  }
  .p-tabview .p-tabview-panels {
    padding: 0;
  }
  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: #F9FAFA;
    border-color: $gray-button-text-color;
    color: $primary-text-color;
  }
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-dropdown, .p-dropdown-label, .p-dropdown-label.p-placeholder {
  display: flex;
  align-items: center;
}

.callback-input-wrap {
  .p-overlay.p-component {
    max-width: 100%
  }
}

.tu-dialogs{
  .p-dialog .p-dialog-header{
    padding: 24px 24px 0 24px;
  }

}

.tu-panel{
  .p-panel.p-panel-toggleable .p-panel-header{
    padding: 24px;
    background: #fff;
    border: 0;
    cursor: pointer;
    border-radius: 16px;
    font-family: Golos;
    transition: 2s;
    font-weight: 500;
    .plus{
      display: block;
    }
    .minus{
      display: none;
    }
    .p-panel-icons{
      display: none;
    }
  }

  .p-panel.p-panel-toggleable.p-panel-expanded .p-panel-header {
    border-radius: 16px 16px 0 0;
    transition: 0s;
    .plus{
      display: none;
    }
    .minus{
      display: block;
    }
  }

  .p-panel .p-panel-content {
    padding: 0 24px 24px 24px;
    border: 0;
    border-radius: 0 0 16px 16px;
  }


}
.account-bg{
  background: #F6F7F8;
}
.tu-status{
  .p-steps ul{
    flex-direction: column;
    gap: 36px;
    li:last-child:before{
        display: none;
    }
  }
  .p-steps .p-steps-item .p-menuitem-link{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .p-steps-item{
    justify-content: flex-start;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-title{
    margin-top: 0;
    margin-left: 10px;
    font-size: 14px;
    display: flex;
    white-space: collapse;
  }
  .p-steps .p-steps-item:before {
    border-top: 0;
    border-left: 1px dashed #CED4DA;
    width: 100%;
    top: 30px;
    height: 75px;
    left: 16px;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number{
    background: #CED4DA;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    border: 0;
  }

}

.main-toast {
  .p-toast {
    width: 50%;
    .p-toast-message {
      border: 1px solid;
      min-height: 50px;
      border-radius: 8px;
      &-error {
        border-color: $error-border-color;
        background: $error-background-color;
      }
      &-success {
        border-color: $success-border-color;
        background: $success-background-color;
      }
      .p-toast-message-content {
        padding: 14px 16px;
        justify-content: space-between;
        display: flex;
        align-items: center;
      }
      .p-toast-icon-close {
        width: 20px;
        height: 20px;
        padding: 10px;
        color: $gray-text-color;
      }
      .message {
        color: $primary-text-color;
        font-size: 14px;
        margin: 0 0 0 12px;
        font-family: Golos;
        font-weight: 400;
      }
    }
  }
}

.p-messages.p-component {
  display: none;
}

button.publish-btn {
  height: 40px;
  padding: 8px 20px;
  border-radius: 8px;
  font-family: Golos;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
}

button.sub-bnt {
  background: $white-button-primary-color;
  border: 1px solid $primary-border-color;
  color: $primary-text-color;
  border-radius: 8px;
  padding: 8px 20px;
  font-family: Golos;
  font-weight: 400;
  margin-right: 10px;
  height: 40px;
}

.p-dialog-header {
  padding: 24px 24px 0 0;
}

.draft .p-button:enabled:hover {
  background: $white-button-primary-color;
  border-color: $primary-border-color;
  color: $primary-text-color;
}

.draft .p-button-label{
  font-weight: 400;
}

input.p-inputtext.p-component.p-element.filter-field.p-inputnumber-input {
  padding-left: 40px;
}

input.p-inputtext.p-component.p-element.filter-number.p-inputnumber-input {
  padding-left: 40px;
}

.callback-count {
  background: $white-button-primary-color;
  max-width: 32px;
  height: 32px;
  font-size: 24px;
  color: $primary-text-color;
}
.carousel-container{
  .p-carousel-prev, .p-carousel-next{
    position: absolute;
  }
  .p-carousel .p-carousel-content{
    .p-carousel-prev{
      left: 51px;
      background: #fff;
      z-index: 2;
      width: 48px;
      height: 48px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
      .p-carousel-prev-icon{
        display: flex;
      }
    }
    .p-carousel-next{
      right: 34px;
      background: #fff;
      z-index: 2;
      width: 48px;
      height: 48px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
      .p-carousel-prev-icon{
        display: flex;
      }
    }
  }
}
app-header{
  .region-wrap{
    .p-dialog{
      width: 416px;
    }
    .p-dialog .p-dialog-content{
      overflow: hidden;
      padding-right: 5px;
    }
    .p-dialog .p-dialog-header{
      padding-bottom: 0;
    }
  }
  ul.p-listbox-list {
    margin-right: 27px;
  }

  .p-listbox{
    border: 0;
  }
  .p-listbox .p-listbox-header{
    padding: 0;
    border: 0;
    margin-right: 32px;
  }
  .p-listbox .p-listbox-list .p-listbox-item.p-highlight{
    background: #E7F3FD;
    color: #363636;
    border-radius: 8px;
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover{
    border-radius: 8px;
  }
  .p-listbox .p-listbox-list .p-listbox-item{
    padding: 8px 16px;
    height: 40px;
  }
  .p-listbox .p-listbox-list .p-listbox-item:focus{
    box-shadow: none;
  }


  .p-listbox-list-wrapper::-webkit-scrollbar{
      width: 6px;
      height: 6px;
  }
  .p-listbox-list-wrapper::-webkit-scrollbar-track{
      background: #FFFFFF;
  }
  .p-listbox-list-wrapper::-webkit-scrollbar-track-piece{
      padding-right: 15px;
  }
  .p-listbox-list-wrapper::-webkit-scrollbar-thumb{
      background: #DADCE0;
      border-radius: 6px;
  }

}
.callback-input-wrap {
  .p-button {
    border: 1px solid $primary-border-color;
  }
  input#horizontal {
    max-width: 147px;
    width: 36px;
  }
}
.sub-bar-container{
  .accordion-wrap{
    .p-accordion .p-accordion-content {
      border: none;
    }
  }
}
.accordion-wrap {
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    position: relative;
    font-size: 14px;
    border-width: 0 0 1px 0;
    padding: 20px 16px;
    border-color: $primary-blue-color;
    margin: 0;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link::before {
    position: absolute;
    width: 100%;
    height: 2px;
    background: $primary-blue-color;
    content: '';
    left: 0;
    border-radius: 6px 6px 0 0;
    bottom: -1px;
    z-index: 3;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    font-size: 14px;
    border-color: transparent transparent  $secondary-border-color transparent;
    padding: 20px 16px;
    border-width: 0 0 1px 0;
    margin: 0;
  }
  .p-accordion .p-accordion-content {
    padding: 0;
    display: flex;
    border: none;
  }
  .p-tabview-nav-container {
    padding: 0;
  }
  .p-tabview .p-tabview-panels {
    padding: 1.25rem 16px;
    border-top: 1px solid $gray-button-text-color;
    margin-top: 20px;
    .p-tabview-panel {
      padding: 0;
      max-height: 440px;
      overflow-y: scroll;
    }
    .p-tabview-panel::-webkit-scrollbar {
      width: 8px;
    }
    .p-tabview-panel::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 10px;
    }
    .p-tabview-panel::-webkit-scrollbar-thumb {
       background: $primary-border-color;
       border-radius: 10px;
     }
    .p-tabview-panel::-webkit-scrollbar-thumb:hover {
      background: #9ca4a9;
    }
  }
  .p-tabview .p-tabview-nav {
    border-width: 0 0 1px 0;
    border-bottom: 2px solid $white-button-primary-color;
    margin: 0 16px;
  }
}

.p-image-preview-container>img {
  width: 100%;
}

.p-image-wrap {
  p-image.p-element {
    width: 25rem;
  }
}

.p-image-wrap span {
  max-height: 400px;
  overflow-y: hidden;
}

.faq {
  .p-link:focus {
    box-shadow: none;
  }
  .p-panel .p-panel-header .p-panel-header-icon:focus {
    box-shadow: none;
  }
  .p-panel .p-panel-content {
    padding: 8px 0 0 0;
    border: 0;
    border-radius: 0;
  }
  .p-panel.p-panel-toggleable .p-panel-header {
    padding: 0;
    background: $white-button-primary-color;
    border: 0;
    font-size: 18px;
  }
  .custom-plus {
    width: 24px;
    height: 24px;
    background: url("/assets/images/main/plus-icon.svg");
  }
  .custom-minus {
    width: 24px;
    height: 24px;
    background: url("/assets/images/main/minus-icon.svg");
  }
}

.breadcrumb {
  display: flex;
  .p-breadcrumb {
    background: transparent;
    border: 0;
    font-size: 14px;
    padding: 16px 0;
    font-family: Golos;
    font-weight: 400;
  }
  .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    color: $gray-text-color;
  }
  .p-breadcrumb ul li:last-child .p-menuitem-text {
    color: $primary-blue-color;
    cursor: default;
  }
  .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text:hover {
    color: $primary-blue-color;
  }
  svg.p-icon {
    width: 20px;
    height: 20px;
    padding: 4px;
  }
}

.status-list {
  .p-multiselect-label-container {
    max-width: 200px;
  }
  .p-multiselect .p-multiselect-label {
    padding: 6px;
    .p-placeholder {
      padding: 6px;
    }
  }
}

.main-table {
  .filter-number {
    padding-left: 40px;
  }
  .p-datatable .p-datatable-thead > tr > th {
    background: #F3F3F3;
    color: #808080;
    font-family: Golos;
    font-weight: 600;
  }
  .p-paginator .p-paginator-current {
    position: absolute;
    right: 10px;
  }
}

.status-check-wrap {
  .p-checkbox {
    height: 16px;
  }
  .p-checkbox .p-checkbox-box {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    .p-icon {
      width: 11px;
    }
  }
}

.card-input.p-inputtext.p-component {
  height: 40px;
  min-width: 204px;
  width: 100%;
  border-radius: 8px
}

.card-input {
  height: 40px;
  min-width: 204px;
  width: 100%;
  border-radius: 8px;
}

.p-multiselect .p-multiselect-label {
  padding: 0.5rem;
}

.card-input.p-dropdown.p-component {
  height: 40px;
  min-width: 204px;
  width: 100%;
  border-radius: 8px
}

.create-card {
  p-inputnumber.p-element.p-inputwrapper {
    width: 100%;
  }
}

.p-column-filter-overlay {
  min-width: 218px;
  max-width: 242px;
}

.p-datatable .p-datatable-tbody > tr > td {
  border: 0;
}

.rowsdropdown {
    .p-dropdown {
      background: #f9fafa;
      border: 0;
      box-shadow: none;
      .p-dropdown-label {
        color: #00A0E3;
      }
      svg {
        color: #00A0E3;
      }
    }
  p-dropdown.p-element.p-inputwrapper {
    position: absolute;
    left: 100px;
  }
}
.table {
  .p-paginator {
    background: #F9FAFA;
    .p-dropdown {
      background: #f9fafa;
      border: 0;
      box-shadow: none;
      .p-dropdown-label {
        color: #00A0E3;
      }
      svg {
        color: #00A0E3;
      }
    }
  }
  p-dropdown.p-element.p-inputwrapper {
    position: absolute;
    left: 100px;
  }
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background: $white-button-primary-color;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar{
  padding: 0;
}

.status-list-wrap {
  height: 30px;
  font-size: 14px;
}

.swiper-slide {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 16px;
}

.swiper{
  z-index: 0;
}

.swiper-slide .rating-wrap img{
  height: 12px;
  margin-right: 5px;
}

.swiper-slide img{
  height: 375px;
}
.swiper-horizontal>.swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal{
  display: none;
}


.create-card {
  .p-tabview .p-tabview-nav {
    background: transparent;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: transparent;
  }
  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: transparent;
  }
  .p-tabview .p-tabview-panels {
    background: transparent;
  }
}

.edit-card {
  .p-tabview .p-tabview-nav {
    background: transparent;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: transparent;
  }
  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: transparent;
  }
  .p-tabview .p-tabview-panels {
    background: transparent;
  }
  .p-chips .p-chips-multiple-container .p-chips-token {
    color: $primary-text-color;
    background: $white-button-primary-color;
    border-radius: 8px;
    border: 1px solid $primary-border-color;
    font-size: 12px;
    font-family: Golos;
    margin-right: 10px;
    font-weight: 500;
  }
  .p-chips .p-chips-multiple-container .p-chips-token:last-child {
    margin-right: 0;
  }
  .p-chips-token-icon {
    width: 16px;
    height: 16px;
  }
  .item-chips {
    ~.p-chips-token-icon {
      display: none;
      .remove {
        background: url("/assets/images/main/tag-cross-gray.svg") center no-repeat;
        width: 16px;
        height: 16px;
        background-size: contain;
      }
    }
    &.red {
      display: flex;
      ~.p-chips-token-icon {
        display: block;
        .remove {
          background: url("/assets/images/main/remove_red.svg") center no-repeat;
          width: 16px;
          height: 16px;
          background-size: contain;
        }
      }
    }
  }
  .city {
    .p-chips .p-chips-multiple-container .p-chips-input-token {
      display: none;
    }
  }
}

.tagDialog .p-dialog .p-dialog-header {
  padding: 8px 8px;
}

.drop {
  height: 40px;
  width: 396px;
}

.clients-sub-img {
  .p-image-preview-container {
    height: 120px;
    width: 200px;
  }
  .p-image-preview-container>img {
    height: 100%;
    width: 100%;
  }
  .p-image-preview-indicator {
    border-radius: 8px;
    padding: 3.9rem;
  }
}

.sign-btn-group {
  .p-ripple.p-element.p-button.p-component {
    margin-right: 8px;
    border-radius: 100px;
    font-family: Golos;
    font-weight: 600;
    margin-top: 16px;
  }
  .p-ripple.p-element.p-button.p-component:last-child {
    margin-right: 0;
  }
}

.emp-modal {
  width: 410px;
}

.create-card {
  .p-steps .p-steps-item:before {
    top: 100%;
    z-index: -1;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin: 0 0 0 10px;
  }
  .p-steps-item .p-menuitem-link {
    flex-direction: row;
    background: $secondary-border-color;
    padding: 0 20px;
  }
  .add-btn {
    background: transparent;
    border: 0;
    padding: 0;
    z-index: 10;
    box-shadow: none;
  }
  .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
    box-shadow: none;
  }
  .p-fileupload-filename {
    display: none;
  }
  .p-fileupload-row{
    color: transparent;
  }
  .p-fileupload-row img {
    display: none;
  }
  button.p-element.p-button-icon-only.p-button.p-component {
    display: none;
  }
  .p-fileupload-choose:not(.p-disabled):hover {
    background: transparent;
    color: $white-button-primary-color;
    border-color: transparent;
  }
  .p-fileupload .p-fileupload-buttonbar{
    padding: 0;
    border: 0;
  }
  .p-fileupload .p-fileupload-buttonbar {
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100px;
    width: 100px;
    align-items: center;
    background: transparent;
  }
  .p-fileupload-content {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    background: transparent;
    border: 1px dashed #ABA7AF;
  }
  .file-err {
    .p-fileupload-content {
      border: 1px dashed #CED4DA;
    }
  }
  .p-fileupload-content .p-progressbar {
    display: none;
  }
  .p-button .p-button-icon-left {
    margin: 0;
  }
  .p-fileupload .p-fileupload-buttonbar .p-button {
    margin: 0;
  }
}

@keyframes blue-spinner-color {
  100%, 0% {
    stroke: $primary-blue-color
  }
}

@keyframes transparent-spinner-color {
  100%, 0% {
    stroke: transparent;
  }
}

.edit-company-wrap.create-card  {
  .p-fileupload-row img {
    width: 118px;
    height: 118px;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    background: $secondary-border-color;
    object-fit: cover;
    object-position: top;
  }
  .banner .p-fileupload-row img {
    width: 511px;
  }
}

.p-editor-toolbar.ql-toolbar.ql-snow {
  text-align: left;
}


.qrcode canvas {
  border: 1px solid $primary-border-color;
  border-radius: 8px;
}

.field-wrap .card-input {
  border-radius: 8px;
}

.hide-arrow svg {
  display: none;
}

.create-card .banner .p-fileupload-content {
  width: 32rem;
}

.create-card .banner .p-fileupload .p-fileupload-buttonbar {
  width: 32rem;
}

.create-card .banner .p-fileupload-row img {
  width: 30rem;
}

.moderator-block .p-tabview .p-tabview-panels {
  padding: 0;
}

.p-tabview .p-tabview-panels {
  padding: 20px 0;
}

.container {
  width: 1440px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  background: $white-button-primary-color;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none;
}
.p-sidebar .p-sidebar-content{
  padding: 0;
}
.p-sidebar .p-sidebar-header{
  padding: 0;
}
.p-sidebar .p-sidebar-footer {
  padding: 0;
}
.desktop-hidden{
  display: none !important;
}

.filter-wrap{
  span.p-slider-handle{
    width: 24px;
    height: 24px;
    top: 0;
  }
  .p-slider-horizontal .p-slider-range{
    height: 3px;
  }
  .p-slider.p-slider-horizontal{
    height: 3px;
    margin: 0 16px 0 12px;
  }
}
.p-overlaypanel .p-overlaypanel-content{
  padding: 0;
}
.p-overlaypanel:after, .p-overlaypanel:before{
  display: none;
}
.p-overlaypanel{
  margin-top: 8px;
}

.ngx-ic-cropper{
  border-radius: 100%;
  outline: rgba(61, 60, 59, 0.5) solid 100vh !important;
}
.cropper-container .ngx-ic-overlay{
  outline: none !important;
}
.cropper-container image-cropper{
  padding: 0;
}

@media (max-width: 1440px){
  .i-container {
    .i-row {
      padding: 0 32px;
      height: 100%;
    }
  }
}

@media (max-width: 1024px){
  .i-container {
    .i-row {
      padding: 0 32px;
      height: 100%;
    }
  }
  .swiper-slide img {
    height: 256px;
  }
}

@media (max-width: 767px) {
  body{
    background: #fff;
  }
  .desktop-hidden{
    display: block;
  }
  .i-container{
    min-width: 360px;
  }
  .filters{
    display: none;
  }
  .search-bar-container{
    display: none !important;
  }
  .accordion-wrap{
    display: none;
  }
  .grid-block{
    display: block !important;
  }
  .i-container .i-row{
    padding: 0 16px;
  }
  .header-wrap {
    display: flex;
    padding: 16px;
  }
  .header-wrap img:first-child{
    margin-right: auto;
  }
  .header-wrap img:last-child{
    margin-left: 16px;
  }
  .carousel-img-wrap{
    .first-slider-header{
      top: 28px;
      left: 28px;
      width: 40%;
      font-size: 20px;
    }
    .first-slider-description{
      top: 81px;
      left: 28px;
      font-size: 14px;
      width: 52%;
    }
  }
  .p-carousel .p-carousel-indicators{
    padding: 10px 0 5px 0;
  }
  .p-carousel-items-container{
    margin: 0 13px;
  }

  .filter-btn {
    width: 100%;
    justify-content: center;
    background: #0D87EF;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
  }

  .filter-btn.p-disabled {
    background: #F0F3F4;
    color: #8D959D;
    border: 1px solid #F0F3F4;
    opacity: 1;
  }

  .callback-input-wrap input#horizontal{
    height: 32px;
  }
  .callback-input{
    height: 40px;
  }

  .callback-count{
    .p-button:enabled:hover{
      background: #fff;
      border-color: #CED4DA;
    }
  }
  .offer-wrap{
    .p-checkbox .p-checkbox-box{
      width: 16px;
      height: 16px;
      border: 1px solid #6C757D;
      border-radius: 4px;
    }
  }

  .main-toast .p-toast .p-toast-message .message {
    margin-top: 0;
  }

  .filter-item{
    .p-sidebar-header{
      margin: 20px 16px;
    }
  }
  .mobile-hidden{
    display: none !important;
  }

  .filter-sidebar .main-sidebar .p-sidebar-full {
    z-index: 1106 !important;
  }

  .card-container .filter-sidebar .main-sidebar .p-sidebar-full{
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  .filter-sidebar .p-sidebar-full {
    z-index: 1107 !important;
  }

  .p-sidebar-bottom {
    border-radius: 16px 16px 0 0;
    height: fit-content;
  }

  .filter-sidebar{
    .p-sidebar-bottom {
      height: fit-content;
      z-index: 1107 !important;
    }
  }
  .p-component-overlay.p-sidebar-mask.p-component-overlay-enter{
    z-index: 1106 !important;
  }

  .p-inplace .p-inplace-display{
    padding: 0;
  }

  .p-listbox .p-listbox-header {
    background: #fff;
    border: none;
  }
  .p-listbox{
    border: none;
  }
  .p-listbox .p-listbox-header .p-listbox-filter{
    background: #F9F9F9;
    border: none;
    padding-right: 12px;
    padding-left: 2.25rem;
  }
  .p-listbox .p-listbox-header .p-listbox-filter-icon{
    right: unset;
    left: 0.75rem;
  }
  .p-listbox .p-listbox-list .p-listbox-item.p-highlight{
    color: inherit;
    background: #fff;
    box-shadow: none;
  }
  .p-listbox .p-listbox-list .p-listbox-item{
    border-bottom: 1px solid #F0F3F4;
    padding: 14px 16px;
  }
  .p-listbox .p-listbox-list{
    padding: 0;
  }
  .universal-callback-button{
    display: none;
  }
  .card-container .p-carousel-container img {
    width: 167px;
    min-height: 100%;
    max-width: 360px;
  }
  .card-container .p-carousel-item-active{
    text-align: center;
  }

  .card-container .p-carousel-items-container{
    margin: 0;
  }
  p{
    margin: 10px 0 0 0;
  }
  .carousel-img-wrap .third-slider-header {
    font-size: 14px;
    top: 19px;
  }
  .carousel-img-wrap .third-slider-description {
    top: 72px;
  }
  .carousel-img-wrap .banner-btn {
    bottom: 19px;
  }
  input[type="search"] {
    -webkit-appearance: textfield;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
  * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .acc-tabs{
    margin: 0;
    overflow: hidden;
  }
  .p-paginator .p-paginator-last{
    display: none;
  }
  .p-paginator .p-paginator-first{
    display: none;
  }
  .p-editor-toolbar.ql-toolbar.ql-snow{
    width: 100%;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow{
    width: 100%;
  }
  button.publish-btn{
    width: 100%;
  }
  app-create-card{
    .p-dialog-header{
      padding: 8px !important;
    }
    .p-dialog{
      width: 96vw !important;
      max-height: 91%;
      margin-bottom: 60px !important;
    }
    .p-dialog .p-dialog-content{
      padding-bottom: 0;
      overflow: unset;
    }
  }
  app-edit-card{
    .p-dialog-header{
      padding: 8px !important;
    }
    .p-dialog{
      width: 96vw !important;
      max-height: 91%;
      margin-bottom: 60px !important;
    }
    .p-dialog .p-dialog-content{
      padding-bottom: 0;
      overflow: unset;
    }
  }
}
