@media (max-width: 1280px) {
  .head-block {
    .menu {
      ul li {
        font-size: 14px;
      }
    }
  }
  .home-content {
    &_blocks {
      .content-block {
        &_item {
          &.service, &.market,
          &.docs, &.marking,
          &.ofd, &.cloud {
            &::before {
              content: "";
              position: absolute;
              top: 30px;
              right: 10px;
              width: 40%;
              height: 100%;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .head-block {
    .menu {
      .head-bred {
        margin: 0 20px 0 30px;
      }
      ul li {
        padding: 24px 12px;
      }
    }
  }
  .home-content {
    &_blocks {
      .content-block {
        &_item {
          padding: 16px 23px;
          overflow: hidden;
          .arrowMove {
            bottom: 20px;
            left: 23px;
          }
        }
      }
    }
  }
}

