@import "./vars";

h1, h2, h3, h4, h5 {
  font-style: normal;
  line-height: 130%;
  font-weight: 600;
  color: $primary-text-color;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 24px;
}
