@import "./vars";

.btn {
  font-family: Golos, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 8px;
  outline: none;
  text-decoration: none;
  height: max-content;
  &:hover {
    cursor: pointer;
  }
  &[data-icon] {
    &:after {
      content: '';
      margin-left: 8px;
    }
    &[data-icon="link"] {
      &:after {
        background-image: url('/assets/images/header/btn-link.png');
        height: 11px;
        width: 20px;
        min-width: 20px;
      }
    }
    &[data-icon="next"] {
      &:after {
        background-image: url('/assets/images/header/arrow_forward.svg');
        height: 24px;
        width: 24px;
        min-width: 24px;
      }
    }
  }
  &[data-icon-before] {
    &:before {
      content: '';
      margin-right: 8px;
    }
    &[data-icon-before="previous"] {
      &:before {
        background-image: url('/assets/images/header/previous_secondary_icon.svg');
        height: 24px;
        width: 24px;
        min-width: 24px;
      }
    }
  }
}

.btn-elg {
  font-size: 18px;
  padding: 16px 24px;
  line-height: 24px;
}

.btn-lg {
  padding: 12px 20px;
  font-size: 16px;
  line-height: 24px;
}

.btn-md {
  padding: 7px 20px;
  font-size: 16px;
  line-height: 24px;
}

.btn-sm {
  padding: 5px 12px;
  font-size: 14px;
  line-height: 20px;
}

.primary-btn-color {
  background-color: $primary-blue-color;
  color: #fff;
  border: 1px solid $primary-blue-color;
  width: max-content;
  transition: all 0.3s ease;
  &:hover {
    background-color: $secondary-blue-color;
  }
  &[disabled] {
    background-color: $secondary-border-color;
    border-color: $secondary-border-color;
    color: $gray-button-text-color;
  }
}

.secondary-btn-color {
  background-color: $white-button-primary-color;
  border: 1px solid $primary-border-color;
  color: $primary-text-color;
  width: max-content;
  transition: all 0.3s ease;
  &:hover {
    background-color: #F6F7F8;
  }
}

.history-back-button:after {
  background-image: url('/assets/images/header/arrow-back.svg')
}

@media (max-width: 680px) {
  #copy-to-clipboard:after {
    background-image: url('/assets/images/header/share-ios-filled.svg')
  }
}
